<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row no-gutters v-if="!preloader">
      <v-col cols="12" md="6" lg="6" v-if="title">
        <v-simple-table>
          <template v-slot:default>
            <thead></thead>
            <tbody>
              <tr>
                <td class="font-weight-bold">Branche</td>
                <td>
                  <span class="pa-3">{{ branchName }}</span>
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Titel</td>
                <td>
                  <DataPatcher
                    :itemValue="title"
                    itemName="title"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="getData"
                  ></DataPatcher>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="!preloader">
      <v-col cols="4">
        <table class="jmp-table">
          <tbody>
            <tr
              v-for="(item, index) in staticSection"
              :key="index"
              :class="typeClass(item.type)"
            >
              <td v-if="item.type === 'month'">{{ whatMonth(item.month) }}</td>
              <td v-else-if="item.type === 'video'">Quartalsvideo</td>

              <td
                v-if="
                  item.type === 'month' && dragSection[index].status !== 'blank'
                "
              >
                <v-select
                  v-model="item.startSunNum"
                  :items="sundayNumbers"
                  item-text="name"
                  item-value="value"
                  label="Sonntag"
                  solo
                  hide-details
                  @change="putAction()"
                >
                </v-select>
              </td>

              <td v-else></td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col cols="8">
        <table class="jmp-table">
          <draggable
            v-model="dragSection"
            tag="tbody"
            :move="handleMove"
            @end="handleDragEnd"
            draggable=".is-draggable"
            handle=".draghandle"
          >
            <tr
              v-for="(item, index) in dragSection"
              :key="index"
              :class="typeClass(item.type) + ' is-draggable'"
            >
              <template v-if="item.status === 'available'">
                <template v-if="item.type === 'month'">
                  <td>
                    <v-select
                      v-model="item.campaigns_id"
                      :items="campaigns"
                      label="Kampagne"
                      item-text="name"
                      item-value="id"
                      solo
                      hide-details
                      @change="putAction()"
                    >
                    </v-select>
                  </td>

                  <td style="width:40px;">
                    <v-btn
                      v-if="item.campaignPreview"
                      fab
                      dark
                      x-small
                      color="blue"
                      elevation="0"
                    >
                      <v-icon dark>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn v-else fab x-small elevation="0" disabled>
                      <v-icon dark>mdi-eye-off</v-icon>
                    </v-btn>
                  </td>

                  <td>
                    <v-select
                      v-model="item.budget"
                      :items="budgets"
                      label="Budget"
                      solo
                      hide-details
                      @change="putAction()"
                    >
                    </v-select>
                  </td>

                  <td style="width:40px;">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="red"
                      elevation="0"
                      @click="clearSlot(index)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </td>

                  <td class="draghandle" style="width:40px;">
                    <v-icon>mdi-drag</v-icon>
                  </td>
                </template>

                <template v-else-if="item.type === 'video'">
                  <td>
                    <v-select
                      v-model="item.video"
                      :items="videos"
                      item-text="name"
                      item-value="id"
                      label="Video"
                      solo
                      hide-details
                      @change="putAction()"
                    >
                    </v-select>
                  </td>

                  <td style="width:40px;">
                    <v-btn
                      v-if="item.videoPreview"
                      fab
                      dark
                      x-small
                      color="blue"
                      elevation="0"
                    >
                      <v-icon dark>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn v-else fab x-small elevation="0" disabled>
                      <v-icon dark>mdi-eye-off</v-icon>
                    </v-btn>
                  </td>

                  <td>
                    <v-select
                      v-model="item.branding"
                      :items="brandings"
                      item-text="name"
                      item-value="value"
                      label="Video"
                      solo
                      hide-details
                      @change="putAction()"
                    >
                    </v-select>
                  </td>

                  <td style="width:40px;">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="red"
                      elevation="0"
                      @click="clearVideoSlot(index)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </td>

                  <td style="width:40px;"></td>
                </template>
              </template>

              <template v-else>
                <template v-if="item.type === 'month'">
                  <td colspan="4" class="text-center">
                    <v-btn
                      fab
                      dark
                      x-small
                      elevation="0"
                      color="grey lighten-2"
                      @click="addNewSlot(index)"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </td>

                  <td class="draghandle" style="width:40px;">
                    <v-icon>mdi-drag</v-icon>
                  </td>
                </template>

                <template v-else-if="item.type === 'video'">
                  <td colspan="4" class="text-center">
                    <v-btn
                      fab
                      dark
                      x-small
                      elevation="0"
                      color="grey darken-2"
                      @click="addNewVideoSlot(index)"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </td>

                  <td style="width:40px;"></td>
                </template>
              </template>
            </tr>
          </draggable>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import apiRequest from "@/mixins/apiRequest";
import DataPatcher from "@/components/ui/DataPatcher.vue";
import PageHeader from "@/components/ui/PageHeader.vue";
export default {
  components: {
    draggable,
    DataPatcher,
    PageHeader,
  },

  created: function() {
    this.getData(); // call it immediatly
  },

  data() {
    return {
      pageTitle: "Jahresmarketing Planvorlage",
      pageDescription: "Hier kannst du eine Vorlage editieren.",
      preloader: false,
      progress: false,
      futureItem: null,
      movingItem: null,
      futureIndex: null,
      movingIndex: null,

      sundayNumbers: [
        {
          name: "1. Sonntag",
          value: "1",
        },
        {
          name: "2. Sonntag",
          value: "2",
        },
        {
          name: "3. Sonntag",
          value: "3",
        },
        {
          name: "4. Sonntag",
          value: "4",
        },
      ],

      campaigns: [],
      videos: [],
      brandings: [
        {
          name: "Premium Branding",
          value: "1",
        },
        {
          name: "Video liegt bereits vor",
          value: "2",
        },
      ],
      budgets: [
        "200.00",
        "300.00",
        "400.00",
        "500.00",
        "600.00",
        "700.00",
        "800.00",
        "900.00",
        "1000.00",
        "1100.00",
        "1200.00",
      ],
      dragSection: [],
      staticSection: [],
      title: null,
      branches_id: null,
      branchName: null,
      patchRoute: "planTemplates/" + this.$route.params.id,
      branchesOptions: [],
    };
  },

  methods: {
    async putAction() {
      let dataArr = new URLSearchParams();
      dataArr.append("dragSection", JSON.stringify(this.dragSection));
      dataArr.append("staticSection", JSON.stringify(this.staticSection));

      this.progress = true;
      await this.putRequest("planTemplates/" + this.$route.params.id, dataArr);
      this.progress = false;

      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = this.$updatedMessage;
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 2000;
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        "planTemplates/" + this.$route.params.id
      );

      if (response) {
        this.dragSection = response.data.data.dragSection;
        this.staticSection = response.data.data.staticSection;
        this.title = response.data.data.title;
        this.branches_id = response.data.data.branches_id;
      }

      //public/products Filter: ?filter[branchIds]=1,3&filter[typeId]=2&filter[categoryIds]=1,2&filter[search]=Suchbegriff
      let productsResponse = await this.getRequest(
        "public/products?filter[branchId]=" +
          this.branches_id +
          "&filter[typeId]=1&filter[isJmp]=1"
      );
      if (productsResponse) {
        /* Hier muss ich einen neuen Array generieren lassen,
        da die Produkte noch mal in einem Object liegen 1: {...}, 2: {...} */
        const that = this;
        Object.keys(productsResponse.data.data).forEach(function(k) {
          that.campaigns.push(productsResponse.data.data[k]);
        });
      }

      let videosResponse = await this.getRequest(
        "public/products?filter[branchId]=" +
          this.branches_id +
          "&filter[typeId]=2&filter[isJmp]=1"
      );
      if (videosResponse) {
        /* Hier muss ich einen neuen Array generieren lassen,
        da die Produkte noch mal in einem Object liegen 1: {...}, 2: {...} */
        const that = this;
        Object.keys(videosResponse.data.data).forEach(function(k) {
          that.videos.push(videosResponse.data.data[k]);
        });
      }

      /* fetch branches products */
      let branchesResponse = await this.getRequest("products/branches");
      if (branchesResponse.status === 200) {
        this.branchesOptions = branchesResponse.data.data;
      }

      this.getBranchName();

      this.preloader = false;
    },

    getBranchName() {
      this.branchesOptions.forEach((item) => {
        if (item.id === this.branches_id) {
          this.branchName = item.name;
        }
      });
    },

    clearSlot(index) {
      const dragData = {
        budget: null,
        campaignPreview: null,
        campaigns_id: null,
        status: "blank",
        type: "month",
      };

      this.$set(this.dragSection, index, dragData);

      // static Section
      const staticData = {
        month: this.staticSection[index].month,
        startSunNum: null,
        type: "month",
      };

      this.$set(this.staticSection, index, staticData);

      this.putAction();
    },

    clearVideoSlot(index) {
      const dragData = {
        branding: null,
        quarter: this.getQuarter(index),
        status: "blank",
        type: "video",
        video: null,
      };

      this.$set(this.dragSection, index, dragData);

      console.log(this.dragSection);
      console.log(this.staticSection);

      this.putAction();
    },

    addNewSlot(index) {
      const slotData = {
        month: this.staticSection[index].month,
        status: "available",
        type: "month",
        campaigns_id: null,
        budget: null,
      };

      this.$set(this.dragSection, index, slotData);

      this.putAction();
    },

    addNewVideoSlot(index) {
      const slotData = {
        quarter: this.getQuarter(index),
        video: null,
        branding: "1",
        status: "available",
        type: "video",
      };

      this.$set(this.dragSection, index, slotData);

      this.putAction();
    },

    getQuarter(index) {
      if (index === 0) {
        return 1;
      } else if (index === 3) {
        return 2;
      } else if (index === 7) {
        return 3;
      } else {
        return 4;
      }
    },

    whatMonth(num) {
      switch (num) {
        case "1":
          return "Januar";
        case "2":
          return "Februar";
        case "3":
          return "März";
        case "4":
          return "April";
        case "5":
          return "Mai";
        case "6":
          return "Juni";
        case "7":
          return "Juli";
        case "8":
          return "August";
        case "9":
          return "September";
        case "10":
          return "Oktober";
        case "11":
          return "November";
        case "12":
          return "Dezember";
      }
    },

    isDisabled(state) {
      if (state === "0") {
        return false;
      } else {
        return true;
      }
    },

    draggableClass(state) {
      if (state === "0") {
        return "is-draggable";
      } else {
        return "is-locked";
      }
    },

    typeClass(state) {
      if (state === "month") {
        return "type-month";
      } else if (state === "blank") {
        return "type-blank";
      } else if (state === "video") {
        return "type-video";
      }
    },

    handleDragEnd() {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.futureItem = this.dragSection[this.futureIndex];
      this.movingItem = this.dragSection[this.movingIndex];

      if (
        this.futureItem &&
        this.futureItem !== this.movingItem &&
        this.futureItem.isLocked !== "1" &&
        this.futureItem.type !== "video"
      ) {
        const _items = Object.assign([], this.dragSection);
        _items[this.futureIndex] = this.movingItem;
        _items[this.movingIndex] = this.futureItem;

        this.dragSection = _items;

        this.futureIndex = null;
        this.movingIndex = null;

        this.putAction();
      }
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;
      return false; // disable sort
    },
  },

  mixins: [apiRequest],
};
</script>

<style>
.draghandle {
  cursor: pointer;
}

.jmp-table .is-locked {
  background-color: red !important;
}

.jmp-table .type-blank {
  background-color: white;
}

.jmp-table .type-month {
  background-color: white;
}

.jmp-table .type-video {
  background-color: #222222 !important;
  color: white;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}

.jmp-table {
  height: auto;
  width: 100%;
  margin: 1em 0;
  border-collapse: collapse;
}

.jmp-table tr {
  background-color: white;
}

.jmp-table td,
.jmp-table th {
  text-align: left;
  padding: 0 0.75rem;
  height: 65px;
}

.jmp-table th {
  font-weight: normal;
  color: gray;
  font-style: italic;
}

.jmp-table td:first-child {
  font-weight: bold;
}

.jmp-table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
